type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
}

export const VideoIcon = ({ svgProps }: Props) => (
  <svg
    viewBox="0 0 24 24"
    height="40"
    width="40"
    aria-hidden="true"
    focusable="false"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <circle cx="12" cy="12" r="10" fill="#131313" />
    <polygon points="9,7 17,12 9,17" fill="#fff" />
  </svg>
)
