import { addQueryParamIfAbsent } from '@/utils/addQueryParamIfAbsent'
import NextImage from 'next/image'
import React from 'react'
import s from './image.module.css'
import cx from 'classnames'

export type ImageLoadingType = 'lazy' | 'eager'

type MediaQuerySource = {
  src: string
  media: string
  width: number
  height: number
}

type ImageProps = Omit<React.ComponentProps<typeof NextImage>, 'ref'> & {
  pictureStyle?: React.CSSProperties
  src?: string
  mediaQuerySources?: MediaQuerySource[]
  videoUrl?: string
  width: number
  height: number
  fit?: boolean
  noFit?: boolean
  noOptimize?: boolean
  noScaleUp?: boolean
  pictureClassName?: string
  loading?: ImageLoadingType
  className?: string
  fill?: boolean
}

export function addOriginalExtensionToPath(originalUrl: string) {
  const regexPattern = /(\d+x\d+\/)/
  const extensionMatch = originalUrl.match(/\.(jpg|png|jpeg|webp)/)
  if (extensionMatch) {
    return originalUrl.replace(regexPattern, `$1${extensionMatch[1]}/`)
  }

  return originalUrl
}

function prependResolutionToPath(
  originalUrl: string,
  width: number,
  height: number
) {
  const regexPattern = /(\d+x\d+\/)/
  const resolutionMatch = originalUrl.match(regexPattern)
  if (!resolutionMatch) {
    try {
      const url = new URL(originalUrl)
      url.pathname = `${width}x${height}` + url.pathname

      return url.toString()
    } catch (error) {
      return originalUrl
    }
  }

  return originalUrl
}

export function overrideImageDimensionsInUrl(
  url: string,
  overrides: { width?: number; height?: number }
): string {
  const resolutionRegex = /(\d+)x(\d+)/
  const match = url?.match(resolutionRegex)

  if (match) {
    // Extract the width and height from the regex match
    const width = overrides.width || parseInt(match[1], 10)
    const height = overrides.height || parseInt(match[2], 10)

    // Replace the old resolution with the new resolution in the URL
    return url?.replace(resolutionRegex, `${width}x${height}`)
  } else {
    return url
  }
}

function containsDomain(input?: string): boolean {
  const domains = [
    'rockmywedding.co.uk',
    'rmw.rn-staging.com',
    'https://ddfvdysqksylx.cloudfront.net',
  ]
  return domains.some((domain) => input?.includes(domain))
}

function isSvgIcon(urlString: string): boolean {
  const svgPattern = /\.svg/i
  return svgPattern.test(urlString)
}

export const Image = ({
  src,
  mediaQuerySources,
  alt,
  pictureStyle,
  width,
  height,
  fit,
  noFit,
  noOptimize,
  noScaleUp,
  pictureClassName,
  loading = 'lazy',
  fill,
  ...restProps
}: ImageProps) => {
  // if no src provided, then we assume mediaQuerySources is provided, so we know it's our image
  const isImageHostedByUs = src ? containsDomain(src) : true

  function processSrc(source: { src: string; width: number; height: number }) {
    if (!source.src) {
      return {
        srcSetWebp: undefined,
        srcSetJpg: undefined,
        src: undefined,
      }
    }

    let srcProcessed = source.src

    if (fit) {
      srcProcessed = addQueryParamIfAbsent(srcProcessed, 'fit', '1')
    }

    if (noFit) {
      srcProcessed = srcProcessed.replace(/&?fit=1/, '')
    }

    /**
     * prepend resolution to path only if width and height are provided
     */
    srcProcessed = prependResolutionToPath(
      srcProcessed,
      source.width,
      source.height
    )

    const srcWithModifiedPath = addOriginalExtensionToPath(srcProcessed)
    const webpSrc = srcWithModifiedPath?.replace(
      /\.(png|jpg|jpeg|webp)/,
      '.webp'
    )

    const scaleUpFactor = noScaleUp ? 1 : 2

    const webpSrc1 = overrideImageDimensionsInUrl(webpSrc, {
      width: source.width,
      height: source.height,
    })
    const webpSrc2 =
      source.width && source.height
        ? overrideImageDimensionsInUrl(webpSrc, {
            width: source.width * scaleUpFactor,
            height: source.height * scaleUpFactor,
          })
        : webpSrc1

    srcProcessed = overrideImageDimensionsInUrl(srcProcessed, {
      width: source.width,
      height: source.height,
    })

    // It causes images to be rendered smaller than they should be on some screens
    // const src2 =
    // source.width && source.height
    //   ? overrideImageDimensionsInUrl(srcProcessed, {
    //       width: source.width * scaleUpFactor,
    //       height: source.height * scaleUpFactor,
    //     })
    //   : srcProcessed

    return {
      srcSetWebp: `${webpSrc1} 1x`,
      srcSetJpg: `${srcProcessed} 1x`,
      srcProcessed,
    }
  }

  if (isSvgIcon(src) || !isImageHostedByUs || noOptimize) {
    return (
      <>
        <NextImage
          width={width}
          height={height}
          alt={alt || ''}
          src={src}
          loading={loading}
          fill={fill}
          unoptimized
          {...restProps}
        />
      </>
    )
  }

  const processFirstSrc = processSrc({
    src: src || mediaQuerySources?.[0].src!,
    width,
    height,
  })

  return (
    <>
      <picture style={pictureStyle} className={pictureClassName}>
        {mediaQuerySources?.map(
          ({ src: sourceSrc, media, width, height }, index) => {
            const { srcSetWebp, srcSetJpg } = processSrc({
              src: sourceSrc,
              width,
              height,
            })

            return (
              <React.Fragment key={index}>
                {media && (
                  <source srcSet={srcSetWebp} type="image/webp" media={media} />
                )}
                {media && (
                  <source srcSet={srcSetJpg} type="image/jpeg" media={media} />
                )}
              </React.Fragment>
            )
          }
        )}
        {!mediaQuerySources && (
          <>
            <source srcSet={processFirstSrc.srcSetWebp} type="image/webp" />
            <source srcSet={processFirstSrc.srcSetJpg} type="image/jpeg" />
          </>
        )}
        <img
          alt={alt}
          src={processFirstSrc.srcProcessed}
          loading={loading}
          decoding={loading === 'lazy' ? 'async' : 'sync'}
          fetchPriority={loading === 'eager' ? 'high' : 'low'}
          {...restProps}
          className={cx(
            {
              [s.fill]: fill,
            },
            restProps.className
          )}
        />
      </picture>
    </>
  )
}
